import axios, { AxiosRequestConfig, AxiosRequestTransformer, AxiosResponse, AxiosResponseTransformer } from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import i18next from "i18next";
import { useSnackbar } from "notistack";
import { FC, createContext, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { parseDate } from "src/GeneralHelper";
import { AppContext, ChildrenProps, baseUrl } from "./AppContextProvider";

// hides errors on console from fetchData method REJECTION_HANDLED is custom key for httpContext errors
window.addEventListener("unhandledrejection", event => {
	if (event.reason === "REJECTION_HANDLED") event.preventDefault();
});

// important
Date.prototype.toJSON = function () {
	// override toJSON so javascipt does not make a timezone conversion automatically
	// getTimezoneOffset returns offset in minutes, multipled by 60000 because 1 minute = 60000ms
	return new Date(this.getTime() - this.getTimezoneOffset() * 60000).toISOString();
};

const responseTransformer: AxiosResponseTransformer = (resData, resHeaders) => {
	//console.log("response tranformer", resData);
	if (resData === undefined || resData === null) return "";
	else if (resData === "") return resData;
	else return JSON.parse(resData, parseDate);
};

const requestTransformer: AxiosRequestTransformer = (reqData, reqHeaders) => {
	// this stops request getting transformed twice when it is retried because of refresh token logic.
	if (reqHeaders.has("transformed")) {
		return reqData;
	}
	reqHeaders["transformed"] = "yes";
	if (reqData instanceof FormData)
		reqHeaders["Content-Type"] = "multipart/form-data";
	return reqData instanceof FormData ? reqData : JSON.stringify(reqData);
};

export type HttpContextProps = {
	postHttp<T = any, TReq = any>(path: string, data?: TReq, successMessage?: string): Promise<AxiosResponse<T, any>>;
	getHttp<T = any, TReq = any>(path: string, queryParams?: TReq): Promise<AxiosResponse<T, any>>;
	deleteHttp<T = any>(path: string, id: string, successMessage?: string): Promise<AxiosResponse<T, any>>;
	putHttp<T = any, TReq = any>(path: string, data?: TReq, successMessage?: string): Promise<AxiosResponse<T, any>>;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const HttpContext = createContext<HttpContextProps>({} as HttpContextProps);

export const HttpContextProvider: FC<ChildrenProps> = ({ children }) => {
	const ctx = useContext(AppContext);
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();

	const [loading, setLoading] = useState(true);

	const doyenAxios = axios.create({
		baseURL: baseUrl(),
		withCredentials: true,
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${ctx.getAuthToken()}`,
		},
		transformRequest: requestTransformer,
		transformResponse: responseTransformer,
	});

	const doyenAxiosRefreshToken = axios.create({
		baseURL: baseUrl(),
		withCredentials: true,
		headers: {
			"Content-Type": "application/json",
		},
		transformRequest: requestTransformer,
		transformResponse: responseTransformer,
	});

	// Function that will be called to refresh authorization
	const refreshAuthLogic = failedRequest => {
		if (ctx.getAuthResponse() === undefined) throw "wub wub not logged in :(";
		return doyenAxiosRefreshToken
			.post("app/refresh-token?tenantId=" + ctx.getAuthResponse().tenantId)
			.then(tokenRefreshResponse => {
				//console.log("refresh token called");
				ctx.setAuthResponse(tokenRefreshResponse.data);
				failedRequest.response.config.headers["Authorization"] = "Bearer " + tokenRefreshResponse.data.token;
				failedRequest.config;
				//failedRequest.headers["Authorization"] = `Bearer ${tokenRefreshResponse.data.token}`;
				return Promise.resolve();
			});
	};
	// TODO: refactor auth response in all project don't use useState hook use local storage directly..
	// TODO: i think allowcredentials broke signalr, fix it.
	doyenAxios.interceptors.request.use(request => {
		request.headers["Authorization"] = `Bearer ${ctx.getAuthToken()}`;
		request.headers["Accept-Language"] = i18next.language;
		return request;
	});

	// Instantiate the interceptor
	createAuthRefreshInterceptor(doyenAxios, refreshAuthLogic);

	const normalizeUrl = (url: string) => {
		return url.startsWith("/") ? url : "/" + url;
	};

	async function fetchData<T>(params: AxiosRequestConfig<any>) {
		try {
			return await doyenAxios.request<T>(params);
		} catch (err) {
			//console.log("axios error:", err);
			if (axios.isAxiosError(err) && err.response) {
				let doyenError = err.response.data as IErrorResponse;
				doyenError.messages.forEach(msg => {
					enqueueSnackbar(msg, { variant: "warning", preventDuplicate: true });
				});
				if (err.response.status === 401) {
					navigate("/login");
				}
			} else {
				enqueueSnackbar(t("SERVER_OFFLINE"), { variant: "error", preventDuplicate: true });
			}
			return Promise.reject("REJECTION_HANDLED");
		} finally {
			setLoading(false);
		}
	}

	async function postHttp<T = any, TReq = any>(url: string, data: TReq): Promise<AxiosResponse<T, any>> {
		let result = await fetchData<T>({
			method: "POST",
			url: normalizeUrl(url),
			data: data,
		});
		//console.log("postHttp result:", result);
		return result;
	}

	async function getHttp<T = any, TReq = any>(url: string, data: TReq): Promise<AxiosResponse<T, any>> {
		let result = await fetchData<T>({
			method: "GET",
			url: normalizeUrl(url),
			params: data,
		});
		//console.log("getHttp result:", result);
		return result;
	}

	async function putHttp<T = any, TReq = any>(url: string, data: TReq): Promise<AxiosResponse<T, any>> {
		let result = await fetchData<T>({
			method: "PUT",
			url: normalizeUrl(url),
			data: data,
		});
		return result;
	}

	async function deleteHttp<T = any>(url: string, id: string): Promise<AxiosResponse<T, any>> {
		let result = await fetchData<T>({
			method: "DELETE",
			url: normalizeUrl(url + "/" + id),
		});
		return result;
	}

	return <HttpContext.Provider
		value={{
			postHttp,
			putHttp,
			getHttp,
			deleteHttp,
		}}>
		{children}
	</HttpContext.Provider>;
};
