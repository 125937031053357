import { SnackbarKey } from "notistack";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";


export const scanNotification = (method: (key?: SnackbarKey) => void) => {
	return {
		variant: "warning",
		persist: true,
		action: (snackbarId?: SnackbarKey) => (
			<IconButton style={{ color: "#fff" }} onClick={() => { method(snackbarId); }}>
				<CancelIcon />
			</IconButton>
		)
	} as any;
};

export const connBreak = () => {
	return {
		variant: "error",
		persist: true,
		preventDuplicate: true
	} as any;
};