import { FC, createContext } from "react";
import { usePersistentStorage } from "src/CustomHooks";
import { persistentStorage } from "src/PersistentStorage";

export const prodUrl = "https://doyen-api.metin.dev";
export const devUrl = "https://localhost:7048";
export const prodImagesUrl = "https://images.metin.dev";
export const subdomainSuffix = ".giyim.app";

export const baseUrl = () => {
	return process.env.NODE_ENV === "development" ? devUrl : prodUrl;
};

export type AppContextProps = {
	setAuthResponse: (user: IAuthResponse) => void;
	getAuthResponse: () => IAuthResponse;
	logoutUser: () => Promise<void>;
	getAuthToken: () => string | undefined;
	user: ICurrentUser;
	setUser: React.Dispatch<ICurrentUser>;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AppContext = createContext<AppContextProps>({} as AppContextProps);

export type ChildrenProps = {
	children?: React.ReactNode
};

export const AppContextProvider: FC<ChildrenProps> = ({ children }) => {
	const [user, setUser] = usePersistentStorage<ICurrentUser>("currentUser", {} as ICurrentUser);

	const setAuthResponse = (authResponse: IAuthResponse) => {
		if (authResponse) {
			persistentStorage.setItem("authResponse", authResponse);
			//console.log("loginUser authResponse", authResponse);
		} else {
			console.error("authResponse has no value");
		}
	};

	const logoutUser = () => {
		setUser({} as ICurrentUser);
		setAuthResponse({} as IAuthResponse);
		return Promise.resolve();
	};

	const getAuthToken = () => {
		return persistentStorage.getItem("authResponse")?.token;
	};

	const getAuthResponse = (): IAuthResponse => {
		return persistentStorage.getItem("authResponse");
	};

	return <AppContext.Provider
		value={{
			getAuthResponse,
			getAuthToken,
			user,
			setUser,
			setAuthResponse,
			logoutUser,
		}}>
		{children}
	</AppContext.Provider>;
};
