import {
	HttpTransportType,
	HubConnection,
	HubConnectionBuilder,
	HubConnectionState,
	IHttpConnectionOptions,
	LogLevel,
} from "@microsoft/signalr";
import { SnackbarKey, useSnackbar } from "notistack";
import { Dispatch, FC, SetStateAction, createContext, useContext, useEffect, useState } from "react";
import { isIOS, isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { usePageVisibility } from "react-page-visibility";
import { useDebounce, useEffectSkipFirst, usePersistentStorage } from "src/CustomHooks";
import { AppContext, ChildrenProps, baseUrl } from "./AppContextProvider";
import { connBreak } from "./SnackbarOptions";

export type HubContextProps = {
	notifications: Array<INotification>;
	clearNotifications: () => void;
	setRead: (idempotencyKey: string) => void;
	setQuantity: (productId: string, newQuantity: number) => void;
	setDiscount: Dispatch<SetStateAction<number>>;
	discount: number;
	cartProducts: Array<ICartProduct>;
	removeFromCart: (productId: string) => void;
	clearCart: () => void;
	subtotal: number;
	defaultTotal: number;
	purcaseTotal: number;
	insertCartProduct: (newCartProducts: Array<ICartProduct>) => void;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const HubContext = createContext<HubContextProps>({} as HubContextProps);

export const HubContextProvider: FC<ChildrenProps> = ({ children }) => {
	const ctx = useContext(AppContext);
	const { t } = useTranslation();
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const isVisible = usePageVisibility();

	const [discount, setDiscount] = usePersistentStorage<number>("discount", 0);
	const [cartProducts, setCartProducts] = usePersistentStorage<Array<ICartProduct>>("cartProducts", []);
	const [notifications, setNotifications] = usePersistentStorage<Array<INotification>>("notifications", []);

	const [hubConnection, setHubConnection] = useState<HubConnection>();
	const [connErrorIds, setConnErrorIds] = useState<Array<SnackbarKey>>([]);

	const showConnError = () => {
		if (ctx.getAuthToken()) {
			setConnErrorIds(ids => [...ids, enqueueSnackbar(t("ConnectionBreak"), connBreak())]);
		}
	};

	const hideConnError = () => {
		connErrorIds.forEach(id => {
			closeSnackbar(id);
		});
		closeSnackbar();
		setConnErrorIds([]);
	};

	const options: IHttpConnectionOptions = {
		transport: HttpTransportType.WebSockets, // needed for skipping cors
		skipNegotiation: true, // needed for skipping cors
		logMessageContent: false,
		logger: LogLevel.Error,
		accessTokenFactory: () => {
			console.log("hub token factory called.");
			return ctx.getAuthToken();
		},
	};

	const createHubConnection = async () => {
		try {
			if (!ctx.getAuthToken() || hubConnection?.state === HubConnectionState.Connected) return;
			const hubCn = new HubConnectionBuilder()
				.withUrl(baseUrl() + "/product-hub", options)
				.withAutomaticReconnect([0, 200, 2000, 2000, 2000, 2000, 10000, 30000, 30000])
				.build();
			await hubCn.start();
			hubCn.on("scanCompleted", scanCompleted);
			hubCn.on("notifyUser", notifyUser);
			hubCn.onreconnecting(() => showConnError());
			hubCn.onreconnected(() => {
				console.log("hub reconnected");
				hideConnError();
				setHubConnection(hubCn);
			});
			setHubConnection(hubCn);
			//console.log("hub connected successfully");
		} catch (e) {
			console.log("hub eksepsiyon", e);
			showConnError();
			setTimeout(async () => createHubConnection(), 3000);
		}
	};

	useEffect(() => {
		if (ctx.getAuthToken()) {
			createHubConnection();
		} else {
			hideConnError(); // don't show error if use is logged out.
			hubConnection?.stop().then(() => {
				console.log("user logged out hub closed.");
			});
		}
	}, [ctx.user]);

	useEffectSkipFirst(() => {
		// safari connection breaks when browser is on the background
		if (isVisible && isIOS && hubConnection?.state !== HubConnectionState.Connected) {
			createHubConnection();
		}
	}, [isVisible]);

	useEffect(() => {
		if (hubConnection?.state === HubConnectionState.Disconnected) {
			showConnError();
		} else if (hubConnection?.state === HubConnectionState.Connected) {
			hideConnError();
		}
	}, [hubConnection?.state]);

	const scanCompleted = (message: IScanResponse) => {
		console.log("hub incoming", message);
		if (!isMobile) {
			// these messages are meant for web admin panel
			if (message.foundMultiple)
				enqueueSnackbar(t("FoundMultipleProductsWithBarcode"), { variant: "warning" });

			message.scanTime = new Date(message.scanTime);
			insertCartProduct(message.existingProducts);
		}
	};

	const notifyUser = (message: INotification) => {
		console.log("hub incoming", message);
		if (!isMobile) {
			message.createdAt = new Date(message.createdAt);
			setNotifications(old => [...old, message]);
		}
	};

	const insertCartProduct = (newCartProducts: Array<ICartProduct>) => {
		setCartProducts(old => {
			const tempCartProducts = [...old];
			newCartProducts.forEach(ncp => {
				const existingCp = tempCartProducts.find(tcp => tcp.productId === ncp.productId);
				if (existingCp) {
					existingCp.quantity += ncp.quantity;
					existingCp.defaultPrice = Math.max(existingCp.defaultPrice, ncp.defaultPrice);
				} else {
					if (ncp.defaultPrice === 0)
						// should't happen but if no price found, it should be added to top.
						tempCartProducts.unshift(ncp);
					else tempCartProducts.push(ncp);
				}
			});
			return tempCartProducts;
		});
	};

	const setRead = (idempotencyKey: string) => {
		setNotifications(val =>
			val.map(item => (item.idempotencyKey === idempotencyKey ? { ...item, read: true } : item)),
		);
	};

	const setQuantity = (productId: string, newQuantity: number) => {
		setCartProducts(val =>
			val.map(item => (item.productId === productId ? { ...item, quantity: newQuantity } : item)),
		);
	};

	const removeFromCart = (productId: string) => setCartProducts(val => val.filter(v => v.productId !== productId));

	const clearCart = () => {
		setCartProducts([]);
		setDiscount(0);
	};

	const subtotal = () =>
		cartProducts.reduce((sum, ep) => sum + (ep.discountedPrice ?? ep.defaultPrice) * ep.quantity, 0);
	const defaultTotal = () => cartProducts.reduce((sum, ep) => sum + ep.defaultPrice * ep.quantity, 0);
	const purcaseTotal = () => cartProducts.reduce((sum, ep) => sum + ep.defaultPurchase * ep.quantity, 0);
	const clearNotifications = () => setNotifications([]);

	const debouncedDiscount = useDebounce(discount, 100);
	useEffect(() => {
		const tempCp = [...cartProducts];
		const discountWeightMultiplier = discount / defaultTotal();
		let cumulativeDiscount = 0;

		tempCp.forEach((cp, index) => {
			if (index < tempCp.length - 1) {
				// / 10 * 10 because rounding to nearest 10
				const itemDiscount = Math.floor((cp.defaultPrice * discountWeightMultiplier) / 5) * 5;
				cp.discountedPrice = cp.defaultPrice - itemDiscount;
				cumulativeDiscount += itemDiscount * cp.quantity;
			} else {
				// approach 1
				// cp.discountedPrice = cp.defaultPrice - Math.floor(((discount - cumulativeDiscount) / cp.quantity) / 12) * 12;
				// approach 2
				// cp.discountedPrice = cp.defaultPrice - Math.floor(((discount - cumulativeDiscount) / cp.quantity));
				cp.discountedPrice = cp.defaultPrice - (discount - cumulativeDiscount) / cp.quantity;
			}
		});
		setCartProducts(tempCp);
		// approach 1 cont
		let discountedTotal = tempCp.reduce((tot, cp) => tot + cp.discountedPrice * cp.quantity, 0);
		let cartTotal = tempCp.reduce((tot, cp) => tot + cp.defaultPrice * cp.quantity, 0);
		let remainingDiscount = discount + discountedTotal - cartTotal;
		console.log("remainingDiscount", remainingDiscount);
		// if (remainingDiscount > 0) {
		// 	for (let i = 0; i < tempCp.length; i++) {
		// 		let perItem = remainingDiscount / tempCp[i].quantity;
		// 		if (Math.floor(perItem * 4) / 4 === perItem) {
		// 			tempCp[i].discountedPrice -= perItem;
		// 			console.log("discount step 2 distributed");
		// 			break;
		// 		} else if (i === tempCp.length - 1) {
		// 			tempCp[i].discountedPrice = Math.floor((tempCp[i].discountedPrice - perItem + Number.EPSILON) * 100) / 100
		// 			// tempCp[i].discountedPrice -= perItem;
		// 			console.warn("discount step 2 CAN NOT distributed");
		// 		}
		// 	}
		// }
		console.log(
			"discounted prices:",
			tempCp.map(cp => cp.discountedPrice),
		);
		// recalculate when new product added or quantity changed or discount changed
	}, [debouncedDiscount, cartProducts.length, cartProducts.reduce((q, cp) => q + cp.quantity, 0)]);

	return <HubContext.Provider
		value={{
			notifications: notifications,
			setRead,
			discount,
			setDiscount,
			setQuantity,
			cartProducts,
			removeFromCart,
			clearCart,
			subtotal: subtotal(),
			defaultTotal: defaultTotal(),
			purcaseTotal: purcaseTotal(),
			clearNotifications: clearNotifications,
			insertCartProduct,
		}}>
		{children}
	</HubContext.Provider>;
};
