import { useContext, useRef, useState } from "react";

import { useNavigate } from "react-router-dom";

import {
	Avatar,
	Box,
	Button,
	Divider,
	Hidden,
	lighten,
	List,
	ListItem,
	ListItemText,
	Popover,
	Typography,
} from "@mui/material";

import ExpandMoreTwoToneIcon from "@mui/icons-material/ExpandMoreTwoTone";
import LockOpenTwoToneIcon from "@mui/icons-material/LockOpenTwoTone";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { AppContext } from "src/contexts/AppContextProvider";
import { HttpContext } from "src/contexts/HttpContextProvider";
import { getImagePath } from "src/GeneralHelper";

const UserBoxButton = styled(Button)(
	({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`,
);

const MenuUserBox = styled(Box)(
	({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`,
);

const UserBoxText = styled(Box)(
	({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`,
);

const UserBoxLabel = styled(Typography)(
	({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`,
);

const UserBoxDescription = styled(Typography)(
	({ theme }) => `
        color: ${lighten(theme.palette.secondary.main, 0.5)}
`,
);

function HeaderUserbox() {
	const ctx = useContext(AppContext);
	const http = useContext(HttpContext);
	const { t, i18n } = useTranslation();

	const ref = useRef<any>(null);
	const [isOpen, setOpen] = useState<boolean>(false);

	const handleOpen = (): void => {
		setOpen(true);
	};

	const handleClose = (): void => {
		setOpen(false);
	};
	const navigate = useNavigate();
	const onLogout = () => {
		http.putHttp("app/logout").then(_ => {
			ctx.logoutUser().then(() => navigate("/login"));
		});
	};

	return <>
		<UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
			<Avatar variant="rounded" alt={ctx.user.fullName} src={getImagePath(ctx.user.companyLogo)} />
			<Hidden mdDown>
				<UserBoxText>
					<UserBoxLabel variant="body1">{ctx.user.fullName}</UserBoxLabel>
					<UserBoxDescription variant="body2">{ctx.user.roleName}</UserBoxDescription>
				</UserBoxText>
			</Hidden>
			<Hidden smDown>
				<ExpandMoreTwoToneIcon sx={{ ml: 1 }} />
			</Hidden>
		</UserBoxButton>
		<Popover
			anchorEl={ref.current}
			onClose={handleClose}
			open={isOpen}
			anchorOrigin={{
				vertical: "top",
				horizontal: "right",
			}}
			transformOrigin={{
				vertical: "top",
				horizontal: "right",
			}}>
			<MenuUserBox sx={{ minWidth: 210 }} display="flex">
				<Avatar variant="rounded" alt={ctx.user.fullName} src={getImagePath(ctx.user.companyLogo)} />
				<UserBoxText>
					<UserBoxLabel variant="body1">{ctx.user.fullName}</UserBoxLabel>
					<UserBoxDescription variant="body2">{ctx.user.roleName}</UserBoxDescription>
				</UserBoxText>
			</MenuUserBox>
			<Divider sx={{ mb: 0 }} />
			<List sx={{ p: 1 }} component="nav">
				<ListItem
					button
					onClick={() => {
						i18n.changeLanguage("en-US");
						handleClose();
					}}>
					<Avatar sx={{ width: 20, height: 20, mr: 2 }} src="/static/images/logo/gb.svg" />
					<ListItemText primary="English" />
				</ListItem>
				<ListItem
					button
					onClick={() => {
						i18n.changeLanguage("tr-TR");
						handleClose();
					}}>
					<Avatar sx={{ width: 20, height: 20, mr: 2 }} src="/static/images/logo/tr.svg" />
					<ListItemText primary="Türkçe" />
				</ListItem>
				<ListItem
					button
					onClick={() => {
						i18n.changeLanguage("ru-RU");
						handleClose();
					}}>
					<Avatar sx={{ width: 20, height: 20, mr: 2 }} src="/static/images/logo/ru.svg" />
					<ListItemText primary="Русский" />
				</ListItem>
				{/* https://upload.wikimedia.org/wikipedia/commons/e/e3/Flag_of_Russia_with_border.svg */}
				{/* https://translate.i18next.com/ */}
			</List>
			<Divider />
			<Box sx={{ m: 1 }}>
				<Button color="primary" fullWidth onClick={onLogout}>
					<LockOpenTwoToneIcon sx={{ mr: 1 }} />
					{t("Logout")}
				</Button>
			</Box>
		</Popover>
	</>;
}

export default HeaderUserbox;
