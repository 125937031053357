import { Dispatch, SetStateAction, useEffect, useLayoutEffect, useRef, useState } from "react";
import { persistentStorage } from "./PersistentStorage";

// this does not do anything in the first run unlike default useEffect hook
export function useEffectSkipFirst(fn: any, inputs: Array<any>) {
	const didMountRef = useRef(false);

	useEffect(() => {
		if (didMountRef.current)
			fn();
		else
			didMountRef.current = true;
	}, inputs);
}

export function usePersistentStorage<T>(key: string, initialValue?: T) {
	const [value, setValue] = useState<T>(() => {
		const valueFromStorage = persistentStorage.getItem(key);

		if (typeof initialValue === "object" &&
			!Array.isArray(initialValue) &&
			initialValue !== null) {
			return {
				...initialValue,
				...valueFromStorage,
			};
		}

		return valueFromStorage || initialValue;
	});

	useEffect(() => {
		persistentStorage.setItem(key, value);
	}, [key, value]);

	return [value, setValue] as const;
}

// Hook
export function useDebounce<T>(value: T, delay: number) {
	// State and setters for debounced value
	const [debouncedValue, setDebouncedValue] = useState(value);

	useEffect(
		() => {
			// Update debounced value after delay
			const handler = setTimeout(() => {
				setDebouncedValue(value);
			}, delay);

			// Cancel the timeout if value changes (also on delay change or unmount)
			// This is how we prevent debounced value from updating if value is changed ...
			// .. within the delay period. Timeout gets cleared and restarted.
			return () => {
				clearTimeout(handler);
			};
		},
		[value, delay] // Only re-call effect if value or delay changes
	);

	return debouncedValue;
}

export const useCountdown = (seconds: number): [number, Dispatch<SetStateAction<number>>] => {
	const [countdown, setCountdown] = useState(seconds);

	const decrement = () =>
		setCountdown((prevTime) => prevTime === 0 ? 0 : prevTime - 1);

	useEffect(() => {
		const interval = setInterval(decrement, 1000);
		return () => clearInterval(interval);
	}, []);

	return [countdown, setCountdown];
};

// thanks to https://github.com/uidotdev/usehooks/blob/main/index.js
export const useWindowSize = () => {
	const [size, setSize] = useState({
	  width: null,
	  height: null,
	});
  
	useLayoutEffect(() => {
	  const handleResize = () => {
		setSize({
		  width: window.innerWidth,
		  height: window.innerHeight,
		});
	  };
  
	  handleResize();
	  window.addEventListener("resize", handleResize);
  
	  return () => {
		window.removeEventListener("resize", handleResize);
	  };
	}, []);
  
	return size;
  }