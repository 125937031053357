import { CssBaseline } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { tr } from "date-fns/locale";
import { ConfirmProvider } from "material-ui-confirm";
import { SnackbarProvider } from "notistack";
import { useRoutes } from "react-router-dom";
import router from "src/router";
import { AppContextProvider } from "./contexts/AppContextProvider";
import { CrudProvider } from "./contexts/CrudContextProvider";
import { HttpContextProvider } from "./contexts/HttpContextProvider";
import { HubContextProvider } from "./contexts/HubContextProvider";
import ThemeProvider from "./theme/ThemeProvider";

function App() {
	const content = useRoutes(router);

	return (
		<ThemeProvider>
			<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={tr}>
				<CssBaseline />
				<SnackbarProvider
					maxSnack={8}
					autoHideDuration={3000}
					anchorOrigin={{
						vertical: "top",
						horizontal: "center",
					}}>
					<AppContextProvider>
						<HttpContextProvider>
							<HubContextProvider>
								<ConfirmProvider
									defaultOptions={{
										confirmationButtonProps: {
											autoFocus: true,
										},
									}}>
									<CrudProvider>{content}</CrudProvider>
								</ConfirmProvider>
							</HubContextProvider>
						</HttpContextProvider>
					</AppContextProvider>
				</SnackbarProvider>
			</LocalizationProvider>
		</ThemeProvider>
	);
}
export default App;
