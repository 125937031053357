import { useContext } from "react";
import { isMobile } from "react-device-detect";
import { Navigate } from "react-router-dom";
import { AppContext } from "src/contexts/AppContextProvider";

function HomePage() {
	const ctx = useContext(AppContext);
	const hostSplit = window.location.host.split(".");
	const subdomain = hostSplit[0];
	return subdomain === "onboarding" && process.env.NODE_ENV === "production" ?
		<Navigate to="/onboard" replace />
		:
		ctx.getAuthToken() ? (
			isMobile ? (
				<Navigate to="/scan" replace />
			) : (
				<Navigate to="/dashboard" replace />
			)
		) : (
			<Navigate to="/login" replace />
		);
}

export default HomePage;
