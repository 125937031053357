import MoneyOffTwoToneIcon from "@mui/icons-material/MoneyOffTwoTone";
import PaidRoundedIcon from "@mui/icons-material/PaidRounded";
import { Box, IconButton, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import HeaderNotifications from "./Notifications";

function HeaderButtons() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	return (
		<Box sx={{ mr: 1 }}>
			<Box sx={{ mx: 0.5 }} component="span">
				<Tooltip arrow title={t("Expenses")} onClick={() => navigate("/expenses")}>
					<IconButton color="info" >
						<MoneyOffTwoToneIcon />
					</IconButton>
				</Tooltip>
			</Box>
			<Box sx={{ mx: 0.5 }} component="span">
				<Tooltip arrow title={t("MakeASale")}>
					<IconButton color="success" onClick={() => navigate("/make-a-sale")}>
						<PaidRoundedIcon />
					</IconButton>
				</Tooltip>
			</Box>
			<Box sx={{ mx: 0.5 }} component="span">
				<HeaderNotifications />
			</Box>
		</Box>
	);
}

export default HeaderButtons;
