import { useContext } from "react";
import { isMobile } from "react-device-detect";
import { Navigate } from "react-router-dom";
import { AppContext } from "src/contexts/AppContextProvider";
import TopMenuLayout from "./LayoutTopMenu";

export const ProtectedRoute = ({ children }) => {
	const ctx = useContext(AppContext);

	if (!ctx.getAuthToken())
		// user is not authenticated
		return <Navigate to="/login" replace />;

	if (isMobile && location.pathname !== "/scan")
		return <Navigate to="/scan" replace />;

	return <TopMenuLayout>{children}</TopMenuLayout>;
};
