import { Suspense, lazy } from "react";
import { RouteObject } from "react-router";
import SuspenseLoader from "src/components/SuspenseLoader";
import BaseLayout from "src/layouts/BaseLayout";
import { ProtectedRoute } from "./layouts/ProtectedRoute";
import HomePage from "./pages/HomePage";

const Loader = (Component) => (props) =>
(
	<Suspense fallback={<SuspenseLoader />}>
		<Component {...props} />
	</Suspense>
);

// Status

const Status404 = Loader(
	lazy(() => import("src/pages/Status404"))
);

const Login = Loader(
	lazy(() => import("src/pages/Login"))
);

const Onboard = Loader(
	lazy(() => import("src/pages/Onboard"))
);

const ProductScan = Loader(
	lazy(() => import("src/pages/ProductScan"))
);

const Dashboard = Loader(
	lazy(() => import("src/pages/AdminDashboard"))
);

const MakeASale = Loader(
	lazy(() => import("src/pages/MakeASale"))
);

const Expenses = Loader(
	lazy(() => import("src/pages/Expenses"))
);

const Customers = Loader(
	lazy(() => import("src/pages/AdminCustomers"))
);

const Brands = Loader(
	lazy(() => import("src/pages/AdminBrands"))
);

const Products = Loader(
	lazy(() => import("src/pages/AdminProducts"))
);

const Categories = Loader(
	lazy(() => import("src/pages/AdminCategories"))
);

const Roles = Loader(
	lazy(() => import("src/pages/AdminRoles"))
);

const Users = Loader(
	lazy(() => import("src/pages/AdminBrands"))
);

const Sales = Loader(
	lazy(() => import("src/pages/AdminSales"))
);

const ProductImageCapture = Loader(
	lazy(() => import("src/pages/ProductImageCapture"))
);

const routes: RouteObject[] = [
	{
		path: "",
		element: <BaseLayout />,
		children: [
			{
				path: "/",
				element: <HomePage />
			},
			{
				path: "/login",
				element: <Login />
			},
			{
				path: "/onboard",
				element: <Onboard />
			},
			{
				path: "/scan",
				element:
					// <ProtectedRouteMobile>
						<ProductScan />
					// </ProtectedRouteMobile>
			},
			{
				path: "/capture",
				element:
					// <ProtectedRouteMobile>
						<ProductImageCapture />
					// </ProtectedRouteMobile>
			},
			{
				path: "/dashboard",
				element:
					<ProtectedRoute>
						<Dashboard />
					</ProtectedRoute>
			},
			{
				path: "/make-a-sale",
				element:
					<ProtectedRoute>
						<MakeASale />
					</ProtectedRoute>
			},
			{
				path: "/expenses",
				element:
					<ProtectedRoute>
						<Expenses />
					</ProtectedRoute>
			},
			{
				path: "/customers",
				element:
					<ProtectedRoute>
						<Customers />
					</ProtectedRoute>
			},
			{
				path: "/brands",
				element:
					<ProtectedRoute>
						<Brands />
					</ProtectedRoute>
			},
			{
				path: "/products",
				element:
					<ProtectedRoute>
						<Products />
					</ProtectedRoute>
			},
			{
				path: "/sales",
				element:
					<ProtectedRoute>
						<Sales />
					</ProtectedRoute>
			},
			{
				path: "/categories",
				element:
					<ProtectedRoute>
						<Categories />
					</ProtectedRoute>
			},
			{
				path: "/roles",
				element:
					<ProtectedRoute>
						<Roles />
					</ProtectedRoute>
			},
			{
				path: "/users",
				element:
					<ProtectedRoute>
						<Users />
					</ProtectedRoute>
			},
			{
				path: "*",
				element: <Status404 />
			}
		]
	}
];

export default routes;
