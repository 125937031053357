import {
	Badge,
	Box,
	ListItem,
	Typography
} from "@mui/material";
import { formatDistance } from "date-fns";
import { tr } from "date-fns/locale";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { HubContext } from "src/contexts/HubContextProvider";

interface INotificationItemProps {
	ntf: INotification
}

function NotificationsItem(props: INotificationItemProps) {

	const hubCtx = useContext(HubContext);
	const { t } = useTranslation();

	return (
		<ListItem button onMouseOver={() => hubCtx.setRead(props.ntf.idempotencyKey)}
			sx={{
				px: 2,
				minWidth: 350,
				display: { xs: "block", sm: "flex" }
			}}
			secondaryAction={!props.ntf.read && <Badge color="success" variant="dot" />}
		>
			<Box flex="1" sx={{
				maxWidth: "350px",
				overflow: "hidden",
				textOverflow: "ellipsis",
				whiteSpace: "nowrap",
			}}>
				<Box display="flex" justifyContent="space-between">
					<Typography sx={{ fontWeight: "bold" }}>
						{t(props.ntf.type + "Header")}
					</Typography>
					<Typography variant="caption" sx={{ textTransform: "none" }}>
						{formatDistance(props.ntf.createdAt, new Date(), {
							addSuffix: true,
							locale: tr
						})}
					</Typography>
				</Box>
				<Typography
					component="span"
					variant="body2"
					color="text.secondary"
				>
					{" "}
					{t(props.ntf.type + "Body", props.ntf.payload as string)}
				</Typography>
			</Box>
		</ListItem>
	);
}

export default NotificationsItem;
