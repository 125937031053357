import NotificationsActiveTwoToneIcon from "@mui/icons-material/NotificationsActiveTwoTone";
import {
	alpha,
	Badge,
	Box,
	Divider,
	IconButton,
	Link,
	List,
	Popover,
	Tooltip,
	Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { HubContext } from "src/contexts/HubContextProvider";
import NotificationsItem from "./NotificationItem";

const NotificationsBadge = styled(Badge)(
	({ theme }) => `
    
    .MuiBadge-badge {
        background-color: ${alpha(theme.palette.error.main, 0.1)};
        color: ${theme.palette.error.main};
        min-width: 16px; 
        height: 16px;
        padding: 0;

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            box-shadow: 0 0 0 1px ${alpha(theme.palette.error.main, 0.3)};
            content: "";
        }
    }
`
);

function HeaderNotifications() {

	const hubCtx = useContext(HubContext);
	const { t } = useTranslation();

	const ref = useRef<any>(null);
	const [isOpen, setOpen] = useState<boolean>(false);

	const handleOpen = (): void => {
		setOpen(true);
	};

	const handleClose = (): void => {
		setOpen(false);
	};

	return (
		<>
			<Tooltip arrow title={t("Notifications")}>
				<IconButton color="primary" ref={ref} onClick={handleOpen}>
					<NotificationsBadge
						badgeContent={hubCtx.notifications.filter(m => !m.read).length}
						anchorOrigin={{
							vertical: "top",
							horizontal: "right"
						}}
					>
						<NotificationsActiveTwoToneIcon />
					</NotificationsBadge>
				</IconButton>
			</Tooltip>
			<Popover
				anchorEl={ref.current}
				onClose={handleClose}
				open={isOpen}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right"
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right"
				}}
			>
				<Box
					sx={{ px: 2, py: 1 }}
					display="flex"
					alignItems="center"
					justifyContent="space-between"
				>
					<Typography variant="h5">{t("Notifications")}</Typography>
				</Box>
				<Divider />
				<List sx={{ p: 0 }}>
					{hubCtx.notifications
						.sortByDesc(m => m.createdAt)
						.slice(0, 20)
						.map(m => <NotificationsItem key={m.idempotencyKey} ntf={m} />)}
				</List>
				{hubCtx.notifications.length === 0 &&
					<Box sx={{
						textAlign: "center",
						minWidth: 350,
						p: 3
					}}>
						<Typography variant="subtitle1" display="block">{t("NoNotifications")}</Typography>
					</Box>
				}
				{hubCtx.notifications.length > 0 &&
					<Box sx={{ textAlign: "center", mb: 1 }}>
						<Link component="button"
							variant="body2"
							onClick={() => {
								hubCtx.clearNotifications();
								handleClose();
							}}
						>
							{t("Clear")}
						</Link>
					</Box>
				}
			</Popover >
		</>
	);
}

export default HeaderNotifications;
