import { format } from "date-fns";
import { tr } from "date-fns/locale";
import { prodImagesUrl } from "./contexts/AppContextProvider";

const reISO = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)(?:Z|(\+|-)([\d|:]*))?$/;
// this converts strings to Date so we can use responses directly in Date format
export const parseDate = (key: string, value: any) => {
	if (typeof value === "string") {
		const a = reISO.exec(value);
		if (a) {
			//console.log("dateregex", value.substring(0, 19));
			// remove Z from datetime string so it does not add timezone offset to already local date
			return new Date(value.substring(0, 19));
		}
	}
	return value;
};

export const formatDate = (date: Date | null, includeTime = false) => {
	return date && format(date, includeTime ? "dd MMMM yyyy HH:mm" : "dd MMMM yyyy", { locale: tr });
};


// window.onerror = (msg, url, line, col, error) => {
// 	console.log("custom on error")
// 	// Note that col & error are new to the HTML 5 spec and may not be 
// 	// supported in every browser.  It worked for me in Chrome.
// 	var extra = !col ? '' : '\ncolumn: ' + col;
// 	extra += !error ? '' : '\nerror: ' + error;
// 	if (!(error instanceof NotFoundException)) {

// 		// You can view the information in an alert to see things working like this:
// 		console.error("Error: " + msg + "\nurl: " + url + "\nline: " + line + extra);
// 	}

// 	// TODO: Report this error via ajax so you can keep track
// 	//       of what pages have JS issues

// 	var suppressErrorAlert = true;
// 	// If you return true, then error alerts (like in older versions of 
// 	// Internet Explorer) will be suppressed.
// 	return suppressErrorAlert;
// };

// window.onunhandledrejection = (e: PromiseRejectionEvent) => {
// 	console.error(e);
// 	throw new Error(e.reason.stack);
// }

export const defaultProduct = "default-product.png";

export const getImagePath = (path: string = defaultProduct) => {
	return prodImagesUrl + "/" + path;
};


export const dataURItoBlob = (dataURI: string) => {
	// convert base64/URLEncoded data component to raw binary data held in a string
	var byteString;
	if (dataURI.split(',')[0].indexOf('base64') >= 0)
		byteString = atob(dataURI.split(',')[1]);
	else
		byteString = unescape(dataURI.split(',')[1]);

	// separate out the mime component
	var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

	// write the bytes of the string to a typed array
	var ia = new Uint8Array(byteString.length);
	for (var i = 0; i < byteString.length; i++) {
		ia[i] = byteString.charCodeAt(i);
	}

	return new Blob([ia], { type: mimeString });
}

const nameof = <T>(name: Extract<keyof T, string>): string => name;

// Schwartzian transform algo
// https://stackoverflow.com/questions/10123953/how-to-sort-an-object-array-by-date-property

declare global {
	interface Array<T> {
		sortBy(o: (p: T) => any): Array<T>;
		sortByDesc(o: (p: T) => any): Array<T>;
	}
}

(function () {
	if (typeof Object.defineProperty === "function") {
		try { Object.defineProperty(Array.prototype, nameof<Array<any>>("sortByDesc"), { value: sb }); } catch (e) { }
	}
	if (!Array.prototype.sortByDesc) Array.prototype.sortByDesc = sb;

	function sb(f: Function) {
		for (let i = this.length; i;) {
			const o = this[--i];
			this[i] = [].concat(f.call(o, o, i), o);
		}
		this.sort(function (a, b) {
			for (let i = 0, len = a.length; i < len; ++i) {
				if (a[i] != b[i]) return a[i] < b[i] ? 1 : -1; // descending
			}
			return 0;
		});
		for (let j = this.length; j;) {
			this[--j] = this[j][this[j].length - 1];
		}
		return this;
	}
})();

(function () {
	if (typeof Object.defineProperty === "function") {
		try { Object.defineProperty(Array.prototype, nameof<Array<any>>("sortBy"), { value: sb }); } catch (e) { }
	}
	if (!Array.prototype.sortBy) Array.prototype.sortBy = sb;

	function sb(f: Function) {
		for (let i = this.length; i;) {
			const o = this[--i];
			this[i] = [].concat(f.call(o, o, i), o);
		}
		this.sort(function (a, b) {
			for (let i = 0, len = a.length; i < len; ++i) {
				if (a[i] != b[i]) return a[i] < b[i] ? -1 : 1; // ascending
			}
			return 0;
		});
		for (let j = this.length; j;) {
			this[--j] = this[j][this[j].length - 1];
		}
		return this;
	}
})();
